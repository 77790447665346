import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import OneSignal from "react-onesignal";
import {backend} from "./config";

const root = ReactDOM.createRoot(document.getElementById('root'));
if(localStorage.getItem("token")){
    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
        return config;
    });
}

navigator.serviceWorker.ready.then(registration => {
    registration.unregister();

    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(async (names) => {
            await Promise.all(names.map(name => caches.delete(name)));
        });
    }
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
