import {Button} from "@mantine/core";
import {usePutCallback} from "use-axios-react";
import {backend} from "../../config";
import {useEffect, useState} from "react";
import s from "styled-components";

export default function EditMode({setEditMode}) {
    const [content, setContent] = useState("");
    const [addPage,sending, {}] = usePutCallback(() => ({
        url: backend("/page"),
        data: {
            content,
            partnerId: localStorage.getItem("partnerId")
        }
    }));

    useEffect(() => {
        if (sending) {
            setEditMode(false);
        }
    }, [sending]);

    return (
        (
            <div style={{
                height: "100%",
            }}>
                <Center>
                    <TextBox
                        onChange={(e) => {
                            e.target.style.height = "auto";
                            e.target.style.height = e.target.scrollHeight + "px";
                            setContent(e.target.value)
                        }}
                        value={content}
                        placeholder="Bir Metin Yazın"
                    />
                </Center>

                <Button
                    onClick={() => {
                        addPage();
                    }}
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                    }}
                >
                    KAYDET
                </Button>
            </div>
        )
    );
}

const TextBox = s.textarea`
    width: 100%;
    text-align: center;
    outline: none;
    border: none;
    box-sizing: border-box;
    font-family: 'Merienda', cursive;
    background: none;
`;

const Center = s.div`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;