import {useEffect, useState} from "react";
import ViewMode from "../book/ViewMode";
import EditMode from "../book/EditMode";
import OneSignal from "react-onesignal";
import axios from "axios";
import {backend} from "../../config";
import { usePostCallback} from "use-axios-react";

async function runOneSignal(callback) {
    OneSignal.init({
        appId: "a8ab9e03-9525-4f7c-b941-29c0e8d76837",
        safari_web_id: "web.onesignal.auto.4da9f50a-e5cd-4bfe-8999-da1af6f61d49",
        notifyButton: {
            enable: true,
        },
    }).then(callback);
}

export const Book = () => {
    const [editMode, setEditMode] = useState(false);

    if (editMode) {
        return <EditMode
            setEditMode={setEditMode}
        />;
    }

    return <ViewMode
        setEditMode={setEditMode}
    />;
}
