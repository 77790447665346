import logo from './logo.svg';
import './App.css';
import {createBrowserRouter, RouterProvider, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import {Login} from "./components/templates/Login";
import {Book} from "./components/templates/Book";
import {MantineProvider} from "@mantine/core";
import axios from "axios";
import OneSignal from 'react-onesignal';
import {backend} from "./config";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/book",
        element: <Book/>,
    }
]);





export default function App() {
    return <MantineProvider
        theme={{
        fontFamily: "Merienda"
    }} withGlobalStyles withNormalizeCSS>
       <RouterProvider router={router}/>

    </MantineProvider>;
}

