import {Button, Input, Space, Text} from "@mantine/core";
import {IconAt} from '@tabler/icons-react';
import s from "styled-components";
import {usePostCallback} from "use-axios-react";
import {backend} from "../../config";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [login, loading, {data, error}] = usePostCallback(() => ({
        url: backend("/login"),
        data: {
            username,
            password
        }
    }));

    useEffect(() => {
        if (localStorage.getItem("token") && localStorage.getItem("partnerId")) {
            navigate('/book');
        }
    }, [])


    useEffect(() => {
        if (data) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("partnerId", data.partnerId);
            location.reload();
        }
    }, [data])


    if (error) {
        console.log(error)
    }
    return (
        <LoginComponent>
            <Logo src={'/icon/poetry.png'}/>
            <Text size={"xl"} align={"center"}>Cennetim</Text>

            <Text size={"xm"} align={"center"}>Devam Etmek İçin Giriş Yapınız</Text>
            <Space h={"md"}/>
            <Input
                icon={<IconAt/>}
                variant="filled"
                placeholder="Kullanıcı Adı"
                radius="md"
                size="md"
                value={username}
                onChange={(e) => {
                    setUsername(e.target.value);
                }}
            />

            <Space h={"md"}/>
            <Input
                icon={<IconAt/>}
                variant="filled"
                placeholder="Şifre"
                radius="md"
                size="md"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            <Space h={"md"}/>

            <Button onClick={() => {
                login();
            }}>GİRİŞ YAP</Button>
        </LoginComponent>
    )
}

const LoginComponent = s.div`
    display: flex;
    height: 100%;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    width:100%;
`;

const Logo = s.img`
    width: 5em;
`;