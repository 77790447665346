import HTMLFlipBook from "react-pageflip";
import {Button} from "@mantine/core";
import {useViewportSize} from "@mantine/hooks";
import {useGetData} from "use-axios-react";
import {backend} from "../../config";
import s from "styled-components";
import {useNavigate} from "react-router-dom";
import Carousel from "../carousel/Carousel";
import Card from "../carousel/Card";

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function hash(data){
    const result = (data.split('').map(i => i.charCodeAt(0)).reduce((a, b) => a + b, 0) % 10) + 1;
    return result > 10 ? 10 : result;
}

export default function ViewMode({setEditMode}) {
    const [data, loading, {error}] = useGetData(backend('/page'));
    const navigate = useNavigate();

    const {height, width} = useViewportSize();
    const isMobile = width <= 768;

    if (error){
        localStorage.setItem("token", "")
        navigate("/");
    }
    if (!data) return <> </>;

    return (
        <div style={{
            height: "100%",
        }}>
            <Carousel
                cards={data.toReversed().map((page, index) => (
                    {
                        key: index,
                        content: (
                            <Card
                                imagen={"/heart/" + hash(page.content) + ".png"}
                                description={page.content}
                                title={page.updatedAt}
                            />
                        )
                    }
                ))}
                height={height}
                width={isMobile ? width : width / 2}
                margin="0 auto"
                offset={2}
                showArrows={false}>
            </Carousel>
            <Button
                onClick={() => setEditMode(true)}
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    cursor: 'grab',
                    'z-index': '1000'
                }}
            >
                DÜZENLE
            </Button>
        </div>
    );
}