import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Button from "./Button";
import {useViewportSize} from "@mantine/hooks";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { tr } from 'date-fns/locale'

function Card({ imagen, title, description }) {
  const [show, setShown] = useState(false);
  const {height, width} = useViewportSize();

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen} alt="" />
      <h4>{formatDistance(subDays(new Date(title), 3), new Date(), { addSuffix: true, locale: tr })}</h4>
      <div style={{
        height: height / 4,
        overflow: "scroll",
        fontSize: "small"
      }}  dangerouslySetInnerHTML={{__html: description}}>
      </div>

    </animated.div>
  );
}

export default Card;
